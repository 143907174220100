import { Roboto, Noto_Sans_Thai } from "next/font/google";
import { alpha, createTheme } from "@mui/material/styles";
import "../theme.d";

const roboto = Roboto({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
});

const notoSansThai = Noto_Sans_Thai({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
});

const preset36 = createTheme({
  name: 'preset36',
  config: {
    // แสดงปุ่มฝากถอนในกระเป๋าหรือไม่ ถ้าเป็น true จะแสดงปุ่ม ถ้าเป็น false จะไม่แสดงปุ่มในกระเป๋า
    // แต่จะแสดงนอกกระเป๋าเงิน
    showDepositAndWithdrawInWallet: true,
    // โลโก้
    logo: {
      // ขนาดโลโก้
      height: 100,
      // ขนาดโลโก้ใน AppBar
      appBarHeight: 80,
      // ระยะห่างของโลโก้ใน AppBar
      appBarSpacing: 0,
    },
  },
  palette: {
    mode: "dark",
    // สีเส้น
    divider: alpha("#FEBE00", 0.4),
    primary: {
      // สีหลัก
      main: "#FEBE00",
      // สีตัวอักษร
      contrastText: "#171203",
    },
    secondary: {
      // สีรอว
      main: "#171203",
      // สีตัวอักษร
      contrastText: "white",
    },
    background: {
      // สีพื้นหลัง
      default: "#140f06",
      // สี Card
      paper: "#000000",
    },
  },
  backgroundImage: {
    // รูปพื้นหลัง
    body: "xxx",
    // ความทึบของรูปพื้นหลัง
    bodyOpacity: 0.5,
    bodySize: "inherit",
  },
  mineColor: {
    // สีพื้นหลังแบบสว่าง
    "paper-soft": "rgba(60,44,15,1)",
    // สีพื้นหลังแบบมืด
    "paper-deep": "#000000",
  },
  shape: {
    // ขนาดขอบมน
    borderRadius: 16,
  },
  gradient: {
    // สีกระเป๋าตอนไม่ได้รับโบนัส
    100: "linear-gradient(197deg, rgba(254, 190, 0,1) 21%, rgba(236,156,33,1) 50%, rgba(236,156,33,1) 75%, rgba(254, 190, 0,1) 100%)",
    // สีกระเป๋าตอนรับโบนัส
    200: "linear-gradient(140deg, rgba(180,176,176,1) 0%, rgba(147,138,138,1) 40%, rgba(232,232,232,1) 100%)",
    // สี Card
    300: "linear-gradient(180deg, rgba(	254, 190, 0,1) 0%, rgba(0, 0, 0	,1) 20%, rgba(0, 0, 0	,1) 90%, rgba(	254, 190, 0,1) 100%)",
    // สี Appbar
    400: "#000000 ",
    // สี background Leaderboard
    610: "#FEBE00 ",
    // สีicon Menu
    620: "#140f06 ",
    // สีicon Menu
    630: "#b8973b ",
    // สีicon Menu
    640: "#FEBE00 ",
    // สีicon Menu
    700: "#000000 ",
  },
  wheel: {
    // ขอบกงล้อ
    borderColor: "#363228",
    segment: {
      // สีกงล้อ 1
      fillStyle1: "#e6c361",
      // สีกงล้อ 2
      fillStyle2: "#2e0d02",
    },
  },
  typography: {
    // ฟอนต์
    fontFamily: [notoSansThai.style.fontFamily, roboto.style.fontFamily].join(
      ","
    ),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: "10px",
          textTransform: "capitalize",
          ...(ownerState.variant === "contained" &&
            ownerState.color === "primary" && {
              background: preset36.palette.primary.main,
            }),
          ...(ownerState.variant === "outlined" && {
            color: preset36.palette.primary.main,
            borderColor: preset36.palette.primary.main,
          }),
        }),
      },
    },
  },
});

export default preset36;
