// Theme Slow88

import { Roboto, Noto_Sans_Thai } from "next/font/google";
import { alpha, createTheme } from "@mui/material/styles";
import "../theme.d";

const roboto = Roboto({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
});

const notoSansThai = Noto_Sans_Thai({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
});

const preset60 = createTheme({
  name: "preset60",
  config: {
    // แสดงปุ่มฝากถอนในกระเป๋าหรือไม่ ถ้าเป็น true จะแสดงปุ่ม ถ้าเป็น false จะไม่แสดงปุ่มในกระเป๋า
    // แต่จะแสดงนอกกระเป๋าเงิน
    showDepositAndWithdrawInWallet: false,
    // โลโก้
    logo: {
      // ขนาดโลโก้
      height: 100,
      // ขนาดโลโก้ใน AppBar
      appBarHeight: 120,
      // ระยะห่างของโลโก้ใน AppBar
      appBarSpacing: 3,
    },
  },
  palette: {
    mode: "dark",
    // สีเส้น
    divider: alpha("#3662ba", 0.5),
    // สีหลัก (สีม่วงดำ)
    primary: {
      main: "#020024 0%",
      contrastText: "#ffffff",
    },
    // สีลอง (ดำขาว)
    secondary: {
      main: "#020024 10%",
      // main: "#ff0040",672e8a
      contrastText: "#ffffff",
    },
    background: {
      // สีพื้นหลัง
      default: "#001f57",
      // สี Card
      paper: "#020024",
    },   
  },
  backgroundImage: {
    // รูปพื้นหลัง
    body: "xxx",
    // ความทึบของรูปพื้นหลัง
    bodyOpacity: undefined,
    bodySize: "contain",
  },
  mineColor: {
    // สีพื้นหลังแบบสว่าง
    "paper-soft": "#020024 10%",
    // สีพื้นหลังแบบมืด
    "paper-deep": "#2e2a78",
  },
  shape: {
    // ขนาดขอบมน
    borderRadius: 16,
  },
  gradient: {
    // สีกระเป๋าตอนไม่ได้รับโบนัส
    100: "",
    // สีกระเป๋าตอนรับโบนัส
    200: "linear-gradient(330deg, rgba(160,198,22,1) 32%, rgba(127,158,12,1) 100%)",
    // สี Card
    300: "linear-gradient(360deg, #001f57, #020024)", 
    // สี Appbar
    // 400: "linear-gradient(360deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 32%, rgba(0,93,255,1) 100%)",
    400: "linear-gradient(360deg, rgba(4,18,63,1) 0%, rgba(24,36,124,1) 100%)",
    // สีปุ่ม Deposit
    500: "linear-gradient(0deg, #109179 0%, #53d2dc 100%)",
    // สีปุ่ม Withdraw
    600: "linear-gradient(360deg, #db991a 0%, #ffc05f 40%, #ffc05f 100%)",
    // สีicon Menu
    620: "#b8973b ",
    // สี Tag
    700: "rgba(0,93,255,1) 100%",
    // สี Logo FooterNav
    800: "#ffeffdb5",
    // สี Text ทั้งหมด
    900: "#ffffff",
    // สี Text 2
    910: "#f9de9b",
    // สี Text MainMenu & TagsFooter
    920: "#ffffff",
    // สี Bg myDeposit
    930: "#fff4e5"

  },
  // background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 32%, rgba(0,93,255,1) 100%);
  wheel: {
    // ขอบกงล้อ
    borderColor: "#386dcd",
    segment: {
      // สีกงล้อ 1
      fillStyle1: "#ffffff",
      // สีกงล้อ 2
      fillStyle2: "#2e2a78",
    },
  },
  typography: {
    // ฟอนต์
    fontFamily: [notoSansThai.style.fontFamily, roboto.style.fontFamily].join(
      ","
    ),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: "10px",
          textTransform: "capitalize",
          ...(ownerState.variant === "contained" && {
            background: preset60.palette.primary.main,
          }),
          ...(ownerState.variant === "outlined" && {
            color: "white",
            borderColor: preset60.palette.primary.main,
            "&:hover": {
              borderColor: "#b49ac7",
            },
          }),
        }),
      },
    },
  },
});

export default preset60;